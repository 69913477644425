const state = {
  selectedOffice: JSON.parse(localStorage.getItem("selectedOffice")) || {
    id: null,
    code: null,
    name: null,
  },
};

const actions = {
  setOffice({ commit }, office) {
    commit("setSelectedOffice", office);
  },
};

const mutations = {
  setSelectedOffice(state, payload) {
    state.selectedOffice = { ...payload };
    localStorage.setItem(
      "selectedOffice",
      JSON.stringify(state.selectedOffice)
    );
  },
};

const getters = {
  getSelectedOffice: (state) => state.selectedOffice,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
