const state = {
  selectedUser: JSON.parse(localStorage.getItem("selectedUser")) || {
    userId: null,
    userName: null,
    firstName: null,
    lastName: null,
    lastLogin: null,
    userImage: null,
    userOffices: null,
    userRoles: null,
  },
};

const actions = {
  setUser({ commit }, office) {
    commit("setSelectedUser", office);
  },
};

const mutations = {
  setSelectedUser(state, payload) {
    state.selectedUser = { ...payload };
    localStorage.setItem(
      "selectedUser",
      JSON.stringify(state.selectedUser)
    );
  },
};

const getters = {
  getSelectedUser: (state) => state.selectedUser,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
