import axios from "axios";

const state = {
  dataAdminCompany: [],
  dataAdminCompanyType: [],
  dataAdminOffice: [],
  // Flags for tracking if data is already fetched
  isDataAdminCompanyLoaded: false,
  isDataAdminCompanyTypeLoaded: false,
  isDataAdminOfficeLoaded: false,
};

const actions = {
  fetchDataAdminCompany({ commit, state }) {
    if (!state.isDataAdminCompanyLoaded) {
      axios
        .get("api/webui/admins/company", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        })
        .then((response) => {
          commit("setDataAdminCompany", response.data.data);
          commit("setDataAdminCompanyLoaded", true); // Set flag to true after fetching
        })
        .catch((error) => {
          console.error("Error fetching master country data:", error);
        });
    }
  },

  fetchDataAdminCompanyType({ commit, state }) {
    if (!state.isDataAdminCompanyTypeLoaded) {
      axios
        .get("api/webui/admins/company/office_type", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        })
        .then((response) => {
          commit("setDataAdminCompanyType", response.data.data);
          commit("setDataAdminCompanyTypeLoaded", true); // Set flag to true after fetching
        })
        .catch((error) => {
          console.error("Error fetching master location type data:", error);
        });
    }
  },

  async fetchDataAdminOffices({ commit, state }) {
    if (!state.isDataAdminOfficeLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/office`, {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataAdminOffice", response.data.data);
        commit("setDataAdminOfficeLoaded", true); // Set flag to true after fetching
        return response;
      } catch (error) {
        console.error("Error fetching office data:", error);
        throw error;
      }
    }
  },
};

const mutations = {
  setDataAdminCompany(state, data) {
    state.dataAdminCompany = data;
  },

  setDataAdminCompanyType(state, data) {
    state.dataAdminCompanyType = data;
  },

  setDataAdminOffice(state, data) {
    state.dataAdminOffice = data;
  },

  // Mutations for setting flags
  setDataAdminCompanyLoaded(state, isLoaded) {
    state.isDataAdminCompanyLoaded = isLoaded;
  },

  setDataAdminCompanyTypeLoaded(state, isLoaded) {
    state.isDataAdminCompanyTypeLoaded = isLoaded;
  },

  setDataAdminOfficeLoaded(state, isLoaded) {
    state.isDataAdminOfficeLoaded = isLoaded;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
