const assetsChildRoutes = (prefix) => [
  {
    path: "assets_profiles",
    name: prefix + "_assets_profiles_overview",
    component: () => import("@/views/asset_driver/asset_profile/AssetProfileOverview.vue"),
  },
  {
    path: "assets_profiles/create",
    name: prefix + "_assets_profiles_add",
    component: () => import("@/views/asset_driver/asset_profile/AssetProfileCreate.vue"),
  },
  {
    path: "assets_profiles/show/:id",
    name: prefix + "_assets_profiles_show",
    component: () => import("@/views/asset_driver/asset_profile/AssetProfileShow.vue"),
  },
  {
    path: "maintenance",
    name: prefix + "_maintenance_overview",
    component: () => import("@/views/asset_driver/bengkel/SPBengkel.vue"),
  },
  {
    path: "inventory",
    name: prefix + "_inventory_overview",
    component: () => import("@/views/asset_driver/sp_inventory/SPInventory.vue"),
  },
];

export default assetsChildRoutes;
