import { createStore } from "vuex";
import adminCompany from "./admin/adminCompany.js";
import adminCustomer from "./admin/adminCustomer.js";
import adminOffice from "./admin/adminOffice.js";
import adminNotif from "./admin/adminNotif.js";
import adminRoles from "./admin/adminRole.js";
import adminUsers from "./admin/adminUser.js";
import assetDrivers from "./asset_driver/assetDrivers.js";
import masterAccount from "./master/masterAccount.js";
import masterCharge from "./master/masterCharge.js";
import masterLocation from "./master/masterLocation.js";
import masterParty from "./master/masterParty.js";
import masterCurrency from "./master/masterCurrency.js";
import masterEquipment from "./master/masterEquipment.js";
import masterMiscellaneous from "./master/masterMiscellaneous.js";
import masterWorkshop from "./master/masterWorkshop.js";
import selectedOffice from "./auth/selectedOffice.js";
import selectedUser from "./auth/selectedUser.js";
import authUser from "./auth/authUser.js";
import rbacUser from "./auth/rbacUser.js";

const store = createStore({
  modules: {
    adminCompany,
    adminCustomer,
    adminOffice,
    adminNotif,
    adminRoles,
    adminUsers,
    assetDrivers,
    masterAccount,
    masterCharge,
    masterLocation,
    masterParty,
    masterCurrency,
    masterEquipment,
    masterMiscellaneous,
    masterWorkshop,
    selectedOffice,
    selectedUser,
    authUser,
    rbacUser,
  },
});

export default store;
