// masterLocation.js
import axios from "axios";

const state = {
  dataMasterCountry: [],
  dataMasterLocation: [],
  dataMasterLocationType: [],
  dataMasterRoute: [],
  dataRouteCustomer: [],

  // Flags to track whether data has been fetched
  isDataMasterCountryLoaded: false,
  isDataMasterLocationLoaded: false,
  isDataMasterLocationTypeLoaded: false,
  isDataMasterRouteLoaded: false,
  isDataRouteCustomerLoaded: false,
};

const actions = {
  async fetchDataMasterCountry({ commit, state }) {
    if (!state.isDataMasterCountryLoaded) {
      try {
        const response = await axios.get("api/webui/master/country", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterCountry", response.data.data);
        commit("setDataMasterCountryLoaded", true);
      } catch (error) {
        console.error("Error fetching master country data:", error);
      }
    }
  },

  async fetchDataMasterLocation({ commit, state }) {
    if (!state.isDataMasterLocationLoaded) {
      try {
        const response = await axios.get("api/webui/master/location", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        const sortedData = response.data.data.sort((a, b) => a.name.localeCompare(b.name));
        commit("setDataMasterLocation", sortedData);
        commit("setDataMasterLocationLoaded", true);
      } catch (error) {
        console.error("Error fetching master location data:", error);
      }
    }
  },

  async fetchDataMasterLocationType({ commit, state }) {
    if (!state.isDataMasterLocationTypeLoaded) {
      try {
        const response = await axios.get("api/webui/master/location_type", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterLocationType", response.data.data);
        commit("setDataMasterLocationTypeLoaded", true);
      } catch (error) {
        console.error("Error fetching master location type data:", error);
      }
    }
  },

  async fetchDataMasterRoute({ commit, state }) {
    if (!state.isDataMasterRouteLoaded) {
      try {
        const response = await axios.get("api/webui/master/rute", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        const sortedData = response.data.data.sort((a, b) => a.name.localeCompare(b.name));
        commit("setDataMasterRoute", sortedData);
        commit("setDataMasterRouteLoaded", true);
      } catch (error) {
        console.error("Error fetching master route data:", error);
      }
    }
  },

  async fetchDataRouteCustomer({ commit, state }, id) {
    if (!state.isDataRouteCustomerLoaded || state.lastCustomerId !== id) {
      try {
        const response = await axios.get("api/webui/master/rute/customer", {
          params: {
            customer_id: id,
          },
        });
        const sortedData = response.data.data.sort((a, b) => a.name.localeCompare(b.name));
        commit("setDataRouteCustomer", sortedData);
        commit("setDataRouteCustomerLoaded", true);
        commit("setLastCustomerId", id);
      } catch (error) {
        console.error("Error fetching route customer data:", error);
      }
    }
  },
};

const mutations = {
  setDataMasterCountry(state, data) {
    state.dataMasterCountry = data;
  },

  setDataMasterLocation(state, data) {
    state.dataMasterLocation = data;
  },

  setDataMasterLocationType(state, data) {
    state.dataMasterLocationType = data;
  },

  setDataMasterRoute(state, data) {
    state.dataMasterRoute = data;
  },

  setDataRouteCustomer(state, data) {
    state.dataRouteCustomer = data;
  },

  // Mutations to set flags indicating data loading status
  setDataMasterCountryLoaded(state, status) {
    state.isDataMasterCountryLoaded = status;
  },
  setDataMasterLocationLoaded(state, status) {
    state.isDataMasterLocationLoaded = status;
  },
  setDataMasterLocationTypeLoaded(state, status) {
    state.isDataMasterLocationTypeLoaded = status;
  },
  setDataMasterRouteLoaded(state, status) {
    state.isDataMasterRouteLoaded = status;
  },
  setDataRouteCustomerLoaded(state, status) {
    state.isDataRouteCustomerLoaded = status;
  },

  // Mutation to keep track of the last customer ID fetched
  setLastCustomerId(state, id) {
    state.lastCustomerId = id;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
