const driversChildRoutes = (prefix) => [
  {
    path: "drivers_profiles",
    name: prefix + "_drivers_profiles_overview",
    component: () => import("@/views/asset_driver/driver_profile/DriverProfileOverview.vue"),
  },
  {
    path: "drivers_profiles/create",
    name: prefix + "_drivers_profiles_add",
    component: () => import("@/views/asset_driver/driver_profile/DriverProfileCreate.vue"),
  },
  {
    path: "drivers_profiles/show/:id",
    name: prefix + "_drivers_profiles_show",
    component: () => import("@/views/asset_driver/driver_profile/DriverProfileShow.vue"),
  },
  {
    path: "technician_profiles",
    name: prefix + "_technician_profiles_overview",
    component: () => import("@/views/asset_driver/technician/TechnicianProfileOverview.vue"),
  },
  {
    path: "technician_profiles/create",
    name: prefix + "_technician_profiles_add",
    component: () => import("@/views/asset_driver/technician/TechnicianProfileCreate.vue"),
  },
  {
    path: "technician_profiles/show/:id",
    name: prefix + "_technician_profiles_show",
    component: () => import("@/views/asset_driver/technician/TechnicianProfileShow.vue"),
  },
  {
    path: "claim_loan",
    name: prefix + "_claim_loan_overview",
    component: () => import("@/views/asset_driver/claim_loan/ClaimLoan.vue"),
  },
];

export default driversChildRoutes;
