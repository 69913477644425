import axios from "axios";

const state = {
  dataNotifList: [],
  dataNotifDriverAge: [],
  dataNotifDriverLicense: [],
  dataNotifAssetLicense: [],
  dataNotifAssetInspection: [],
  // Flags for tracking each fetch
  isDataNotifListLoaded: false,
  isDataNotifDriverAgeLoaded: false,
  isDataNotifDriverLicenseLoaded: false,
  isDataNotifAssetLicenseLoaded: false,
  isDataNotifAssetInspectionLoaded: false,
};

const actions = {
  async fetchDataNotifList({ commit, state }) {
    if (!state.isDataNotifListLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/notif/list`, {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataNotifList", response.data.data);
        commit("setDataNotifListLoaded", true); // Set the flag after fetching
        return response;
      } catch (error) {
        console.error("Error fetching :", error);
        throw error;
      }
    }
  },
  
  async fetchDataNotifDriverAge({ commit, state }) {
    if (!state.isDataNotifDriverAgeLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/notif/driver_age`, {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataNotifDriverAge", response.data.data);
        commit("setDataNotifDriverAgeLoaded", true); // Set the flag after fetching
        return response;
      } catch (error) {
        console.error("Error fetching :", error);
        throw error;
      }
    }
  },
  
  async fetchDataNotifDriverLicense({ commit, state }) {
    if (!state.isDataNotifDriverLicenseLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/notif/driver_license`);
        commit("setDataNotifDriverLicense", response.data.data);
        commit("setDataNotifDriverLicenseLoaded", true); // Set the flag after fetching
        return response;
      } catch (error) {
        console.error("Error fetching  :", error);
        throw error;
      }
    }
  },

  async fetchDataNotifAssetLicense({ commit, state }) {
    if (!state.isDataNotifAssetLicenseLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/notif/asset_register`);
        commit("setDataNotifAssetLicense", response.data.data);
        commit("setDataNotifAssetLicenseLoaded", true); // Set the flag after fetching
        return response;
      } catch (error) {
        console.error("Error fetching :", error);
        throw error;
      }
    }
  },

  async fetchDataNotifAssetInspection({ commit, state }) {
    if (!state.isDataNotifAssetInspectionLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/notif/asset_inspection`);
        commit("setDataNotifAssetInspection", response.data.data);
        commit("setDataNotifAssetInspectionLoaded", true); // Set the flag after fetching
        return response;
      } catch (error) {
        console.error("Error fetching :", error);
        throw error;
      }
    }
  },
};

const mutations = {
  setDataNotifList(state, data) {
    state.dataNotifList = data;
  },
  setDataNotifDriverAge(state, data) {
    state.dataNotifDriverAge = data;
  },
  setDataNotifDriverLicense(state, data) {
    state.dataNotifDriverLicense = data;
  },
  setDataNotifAssetLicense(state, data) {
    state.dataNotifAssetLicense = data;
  },
  setDataNotifAssetInspection(state, data) {
    state.dataNotifAssetInspection = data;
  },
  // Mutations for setting flags
  setDataNotifListLoaded(state, isLoaded) {
    state.isDataNotifListLoaded = isLoaded;
  },
  setDataNotifDriverAgeLoaded(state, isLoaded) {
    state.isDataNotifDriverAgeLoaded = isLoaded;
  },
  setDataNotifDriverLicenseLoaded(state, isLoaded) {
    state.isDataNotifDriverLicenseLoaded = isLoaded;
  },
  setDataNotifAssetLicenseLoaded(state, isLoaded) {
    state.isDataNotifAssetLicenseLoaded = isLoaded;
  },
  setDataNotifAssetInspectionLoaded(state, isLoaded) {
    state.isDataNotifAssetInspectionLoaded = isLoaded;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
