// masterLocation.js
import axios from "axios";

const state = {
  dataMasterAccount: [],
  dataMasterMainBank: [],
  dataMasterUserBank: [],
  dataMasterBank: [],
  dataJobList: [],
  dataImprestJob: [],
  dataMasterUserAccount: [],

  // Flags to track whether data has been fetched
  isDataMasterAccountLoaded: false,
  isDataMasterMainBankLoaded: false,
  isDataMasterUserBankLoaded: false,
  isDataMasterBankLoaded: false,
  isDataJobListLoaded: false,
  isDataImprestJobLoaded: false,
  isDataMasterUserAccountLoaded: false,
};

const actions = {
  async fetchDataMasterAccount({ commit, state }, cash_account) {
    if (!state.isDataMasterAccountLoaded) {
      const baseURL = cash_account === "Bank"
        ? "api/webui/master/bank"
        : "api/webui/master/cashier";
      
      try {
        const response = await axios.get(baseURL, {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterAccount", response.data.data);
        commit("setDataMasterAccountLoaded", true);
        return response;
      } catch (error) {
        console.error("Error fetching master account data:", error);
        throw error;
      }
    }
  },

  async fetchDataJobList({ commit, state }, { is_revenue, office_id }) {
    if (!state.isDataJobListLoaded) {
      try {
        const response = await axios.get("api/webui/cash/advance_request/job_list", {
          params: {
            is_revenue: is_revenue,
            office_id: office_id,
          },
        });
        commit("setDataJobList", response.data.data);
        commit("setDataJobListLoaded", true);
        return response;
      } catch (error) {
        console.error("Error fetching job list data:", error);
        throw error;
      }
    }
  },

  async fetchDataMasterMainBank({ commit, state }) {
    if (!state.isDataMasterMainBankLoaded) {
      try {
        const response = await axios.get("api/webui/master/bank", {
          params: {
            search_by: "is_main",
            search_value: 1,
          },
        });
        commit("setDataMasterMainBank", response.data.data);
        commit("setDataMasterMainBankLoaded", true);
        return response;
      } catch (error) {
        console.error("Error fetching main bank data:", error);
        throw error;
      }
    }
  },

  async fetchDataMasterUserBank({ commit, state }, user_id) {
    if (!state.isDataMasterUserBankLoaded) {
      try {
        const response = await axios.get("api/webui/admins/user_bank/" + user_id);
        commit("setDataMasterUserBank", response.data.data);
        commit("setDataMasterUserBankLoaded", true);
        return response;
      } catch (error) {
        console.error("Error fetching user bank data:", error);
        throw error;
      }
    }
  },

  async fetchDataMasterUserAccount({ commit, state }, { cash_account, user_id }) {
    if (!state.isDataMasterUserAccountLoaded) {
      const baseURL = cash_account === "Bank"
        ? "api/webui/admins/user_bank/" + user_id
        : "api/webui/admins/user_cash/" + user_id;

      try {
        const response = await axios.get(baseURL);
        commit("setDataMasterUserAccount", response.data.data);
        commit("setDataMasterUserAccountLoaded", true);
        return response;
      } catch (error) {
        console.error("Error fetching user account data:", error);
        throw error;
      }
    }
  },

  async fetchDataMasterBank({ commit, state }) {
    if (!state.isDataMasterBankLoaded) {
      try {
        const response = await axios.get("api/webui/master/bank", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterBank", response.data.data);
        commit("setDataMasterBankLoaded", true);
        return response;
      } catch (error) {
        console.error("Error fetching bank data:", error);
        throw error;
      }
    }
  },

  async fetchDataImprestJob({ commit, state }) {
    if (!state.isDataImprestJobLoaded) {
      try {
        const response = await axios.get("api/webui/cash/imprest/job_list");
        commit("setDataImprestJob", response.data.data);
        commit("setDataImprestJobLoaded", true);
        return response;
      } catch (error) {
        console.error("Error fetching imprest job data:", error);
        throw error;
      }
    }
  },
};

const mutations = {
  setDataMasterAccount(state, data) {
    state.dataMasterAccount = data;
  },

  setDataJobList(state, data) {
    state.dataJobList = data;
  },

  setDataMasterMainBank(state, data) {
    state.dataMasterMainBank = data;
  },

  setDataMasterUserBank(state, data) {
    state.dataMasterUserBank = data;
  },

  setDataMasterUserAccount(state, data) {
    state.dataMasterUserAccount = data;
  },

  setDataMasterBank(state, data) {
    state.dataMasterBank = data;
  },

  setDataImprestJob(state, data) {
    state.dataImprestJob = data;
  },

  // Mutation to set flags indicating data loading status
  setDataMasterAccountLoaded(state, status) {
    state.isDataMasterAccountLoaded = status;
  },
  setDataJobListLoaded(state, status) {
    state.isDataJobListLoaded = status;
  },
  setDataMasterMainBankLoaded(state, status) {
    state.isDataMasterMainBankLoaded = status;
  },
  setDataMasterUserBankLoaded(state, status) {
    state.isDataMasterUserBankLoaded = status;
  },
  setDataMasterUserAccountLoaded(state, status) {
    state.isDataMasterUserAccountLoaded = status;
  },
  setDataMasterBankLoaded(state, status) {
    state.isDataMasterBankLoaded = status;
  },
  setDataImprestJobLoaded(state, status) {
    state.isDataImprestJobLoaded = status;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
