const dashboardChildRoutes = (prefix) => [
  {
    path: "home",
    name: prefix,
    component: () => import("@/views/dashboard/HomePage.vue"),
  },
  {
    path: "home",
    name: prefix,
    component: () => import("@/views/dashboard/HomePage.vue"),
  },
];

export default dashboardChildRoutes;
