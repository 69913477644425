// masterRoles.js
import axios from "axios";

const state = {
  dataAdminRoles: [],
  dataAdminRolesDetail: [],
  // Flags to track whether data has been fetched
  isDataAdminRolesLoaded: false,
  isDataAdminRolesDetailLoaded: false,
};

const actions = {
  async fetchDataAdminRoles({ commit, state }) {
    if (!state.isDataAdminRolesLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/roles`, {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataAdminRoles", response.data.data);
        commit("setDataAdminRolesLoaded", true); // Set the flag after fetching
        return response;
      } catch (error) {
        console.error("Error fetching roles data:", error);
        throw error;
      }
    }
  },

  async fetchDataAdminRolesDetail({ commit, state }, id) {
    if (!state.isDataAdminRolesDetailLoaded) {
      try {
        const response = await axios.get(`api/webui/admins/roles/view/${id}`);
        commit("setDataAdminRolesDetail", response.data.data);
        commit("setDataAdminRolesDetailLoaded", true); // Set the flag after fetching
        return response;
      } catch (error) {
        console.error("Error fetching roles detail data:", error);
        throw error;
      }
    }
  },
};

const mutations = {
  setDataAdminRoles(state, data) {
    state.dataAdminRoles = data;
  },

  setDataAdminRolesDetail(state, data) {
    state.dataAdminRolesDetail = data;
  },

  // Mutations for setting flags
  setDataAdminRolesLoaded(state, isLoaded) {
    state.isDataAdminRolesLoaded = isLoaded;
  },
  setDataAdminRolesDetailLoaded(state, isLoaded) {
    state.isDataAdminRolesDetailLoaded = isLoaded;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
