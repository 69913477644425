const jobChildRoutes = (prefix) => [
  {
    path: "air",
    name: prefix + "_air",
    component: () => import("@/views/job_order/air/JobAirPage.vue"),
  },
  {
    path: "road",
    name: prefix + "_road_overview",
    component: () => import("@/views/job_order/road/JobRoadOverview.vue"),
  },
  {
    path: "road/show/:id",
    name: prefix + "_road_show",
    component: () => import("@/views/job_order/road/JobRoadShow.vue"),
  },
  {
    path: "sea",
    name: prefix + "_sea",
    component: () => import("@/views/job_order/sea/JobSeaPage.vue"),
  },
  {
    path: "transmittal",
    name: prefix + "_transmittal_overview",
    component: () => import("@/views/job_order/transmittal/TransmittalOverview.vue"),
  },
];

export default jobChildRoutes;
