import axios from "axios";

const state = {
  rbacData: null,
  isRbacDataLoaded: false,
};

const getters = {
  rbacData: (state) => state.rbacData,
  isRbacDataLoaded: (state) => state.isRbacDataLoaded,
};

const mutations = {
  SET_RBAC_DATA(state, rbacData) {
    state.rbacData = rbacData;
    state.isRbacDataLoaded = true;
  },
};

const actions = {
  async fetchRBACData({ commit, state }, userId) {
    if (state.isRbacDataLoaded) {
      return false;
    }

    try {
      const response = await axios.get(
        "api/webui/admins/users/roles/" + userId
      );
      const rbacData = response.data.data;
      commit("SET_RBAC_DATA", rbacData);
      return true;
    } catch (error) {
      console.error("Error fetching RBAC data:", error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
