// masterLocation.js
import axios from "axios";

const state = {
  dataMasterCurrency: [],
  // Flags to track whether data has been fetched
  isDataMasterCurrencyLoaded: false,
};

const actions = {
  fetchDataMasterCurrency({ commit }) {
    if (!state.isDataMasterCurrencyLoaded) {
      axios
        .get("api/webui/master/currency", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        })
        .then((response) => {
          commit("setDataMasterCurrency", response.data.data);
          commit("setDataMasterCurrencyLoaded", true); // Set flag after data is loaded
        })
        .catch((error) => {
          console.error("Error fetching master currency data:", error);
        });
    }
  },
};

const mutations = {
  setDataMasterCurrency(state, data) {
    state.dataMasterCurrency = data;
  },
  // Mutations for setting flags after loading data
  setDataMasterCurrencyLoaded(state, isLoaded) {
    state.isDataMasterCurrencyLoaded = isLoaded;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
