const financeChildRoutes = (prefix) => [
  {
    path: "advance_request",
    name: prefix + "_advance_request_overview",
    component: () => import("@/views/finance/advance_request/AdvanceOverview.vue"),
  },
  {
    path: "advance_request/create",
    name: prefix + "_advance_request_add",
    component: () => import("@/views/finance/advance_request/AdvanceCreate.vue"),
  },
  {
    path: "advance_request/show/:id",
    name: prefix + "_advance_request_show",
    component: () => import("@/views/finance/advance_request/AdvanceShow.vue"),
  },
  {
    path: "settlement",
    name: prefix + "_settlement_overview",
    component: () => import("@/views/finance/settlement/SettlementOverview.vue"),
  },
  {
    path: "settlement/create",
    name: prefix + "_settlement_add",
    component: () => import("@/views/finance/settlement/SettlementCreate.vue"),
  },
  {
    path: "settlement/show/:id",
    name: prefix + "_settlement_show",
    component: () => import("@/views/finance/settlement/SettlementShow.vue"),
  },
  {
    path: "costing",
    name: prefix + "_costing_overview",
    component: () => import("@/views/finance/costing/CostingOverview.vue"),
  },
  {
    path: "costing/create",
    name: prefix + "_costing_add",
    component: () => import("@/views/finance/costing/CostingCreate.vue"),
  },
  {
    path: "costing/show/:id",
    name: prefix + "_costing_show",
    component: () => import("@/views/finance/costing/CostingShow.vue"),
  },
  {
    path: "billing",
    name: prefix + "_billing_overview",
    component: () => import("@/views/finance/billing/BillingPage.vue"),
  },
  {
    path: "imprest",
    name: prefix + "_imprest_overview",
    component: () => import("@/views/finance/imprest/ImprestOverview.vue"),
  },
];

export default financeChildRoutes;
