// masterLocation.js
import axios from "axios";

const state = {
  dataMasterCharge: [],
  // Flags to track whether data has been fetched
  isDataMasterChargeLoaded: false,
};

const actions = {
  fetchDataMasterCharge({ commit, state }) {
    if (!state.isDataMasterChargeLoaded) {
      axios
        .get("api/webui/master/charge", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        })
        .then((response) => {
          commit("setDataMasterCharge", response.data.data);
          commit("setDataMasterChargeLoaded", true); // Set flag after data is loaded
        })
        .catch((error) => {
          console.error("Error fetching master charge data:", error);
        });
    }
  },
};

const mutations = {
  setDataMasterCharge(state, data) {
    state.dataMasterCharge = data;
  },

  // Mutations for setting flags after loading data
  setDataMasterChargeLoaded(state, isLoaded) {
    state.isDataMasterChargeLoaded = isLoaded;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
