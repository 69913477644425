const bookingChildRoutes = (prefix) => [
  {
    path: "overview",
    name: prefix + "_overview",
    component: () => import("@/views/booking/BookingOverview.vue"),
  },
  {
    path: "create",
    name: prefix + "_create",
    component: () => import("@/views/booking/BookingCreate.vue"),
  },
  {
    path: "show/:id",
    name: prefix + "_show",
    component: () => import("@/views/booking/BookingShow.vue"),
  },
  {
    path: "upload",
    name: prefix + "_upload",
    component: () => import("@/views/booking/BookingUpload.vue"),
  },
];

export default bookingChildRoutes;
