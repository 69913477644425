const dashboardChildRoutes = (prefix) => [
  {
    path: "",
    name: prefix,
    component: () => import("@/views/master/MasterPage.vue"),
  },
  {
    path: "overview",
    name: prefix + "_overview",
    component: () => import("@/views/booking/BookingOverview.vue"),
  },
];

export default dashboardChildRoutes;
