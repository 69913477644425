// masterLocation.js
import axios from "axios";

const state = {
  dataMasterDebtor: [],
  dataMasterDebtorByCustomer: [],
  dataMasterVendor: [],
  dataMasterParty: [],
  dataCostInvoiceList: [],
  dataMasterShipper: [],
  dataMasterConsignee: [],
  dataMasterStockPoint: [],

  // Flags to track whether data has been fetched
  isDataMasterDebtorLoaded: false,
  isDataMasterDebtorByCustomerLoaded: false,
  isDataMasterVendorLoaded: false,
  isDataMasterPartyLoaded: false,
  isDataCostInvoiceListLoaded: false,
  isDataMasterShipperLoaded: false,
  isDataMasterConsigneeLoaded: false,
  isDataMasterStockPointLoaded: false,
};

const actions = {
  async fetchDataMasterDebtor({ commit, state }) {
    if (!state.isDataMasterDebtorLoaded) {
      try {
        const response = await axios.get("api/webui/master/debtor", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterDebtor", response.data.data);
        commit("setDataMasterDebtorLoaded", true);
      } catch (error) {
        console.error("Error fetching master debtor data:", error);
      }
    }
  },

  async fetchDataMasterDebtorByCustomer({ commit, state }, customer) {
    if (!state.isDataMasterDebtorByCustomerLoaded || state.customer !== customer) {
      try {
        const response = await axios.get(`api/webui/master/debtor/customer/${customer}`);
        commit("setDataMasterDebtorByCustomer", response.data.data);
        commit("setDataMasterDebtorByCustomerLoaded", true);
        commit("setCustomer", customer);
      } catch (error) {
        console.error("Error fetching master debtor data:", error);
      }
    }
  },

  async fetchDataMasterVendor({ commit, state }) {
    if (!state.isDataMasterVendorLoaded) {
      try {
        const response = await axios.get("api/webui/master/vendor", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterVendor", response.data.data);
        commit("setDataMasterVendorLoaded", true);
      } catch (error) {
        console.error("Error fetching master vendor data:", error);
      }
    }
  },

  async fetchDataMasterParty({ commit, state }) {
    if (!state.isDataMasterPartyLoaded) {
      try {
        const response = await axios.get("api/webui/master/party", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterParty", response.data.data);
        commit("setDataMasterPartyLoaded", true);
      } catch (error) {
        console.error("Error fetching master party data:", error);
      }
    }
  },

  async fetchDataCostInvoiceList({ commit, state }, { status, office_id }) {
    if (!state.isDataCostInvoiceListLoaded || state.status !== status || state.office_id !== office_id) {
      try {
        const response = await axios.get("api/webui/cash/costing", {
          params: {
            status: status,
            office_id: office_id,
          },
        });
        commit("setDataCostInvoiceList", response.data.data);
        commit("setDataCostInvoiceListLoaded", true);
        commit("setCostInvoiceParams", { status, office_id });
      } catch (error) {
        console.error("Error fetching cost invoice list data:", error);
      }
    }
  },

  async fetchDataMasterShipper({ commit, state }) {
    if (!state.isDataMasterShipperLoaded) {
      try {
        const response = await axios.get("api/webui/master/party", {
          params: {
            party_type: "clientShipper",
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterShipper", response.data.data);
        commit("setDataMasterShipperLoaded", true);
      } catch (error) {
        console.error("Error fetching master Shipper data:", error);
      }
    }
  },

  async fetchDataMasterConsignee({ commit, state }) {
    if (!state.isDataMasterConsigneeLoaded) {
      try {
        const response = await axios.get("api/webui/master/party", {
          params: {
            party_type: "clientConsignee",
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterConsignee", response.data.data);
        commit("setDataMasterConsigneeLoaded", true);
      } catch (error) {
        console.error("Error fetching master Consignee data:", error);
      }
    }
  },

  async fetchDataMasterStockPoint({ commit, state }) {
    if (!state.isDataMasterStockPointLoaded) {
      try {
        const response = await axios.get("api/webui/master/party", {
          params: {
            party_type: "clientStockPoint",
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterStockPoint", response.data.data);
        commit("setDataMasterStockPointLoaded", true);
      } catch (error) {
        console.error("Error fetching master StockPoint data:", error);
      }
    }
  },
};

const mutations = {
  setDataMasterDebtor(state, data) {
    state.dataMasterDebtor = data;
  },

  setDataMasterDebtorByCustomer(state, data) {
    state.dataMasterDebtorByCustomer = data;
  },

  setDataMasterVendor(state, data) {
    state.dataMasterVendor = data;
  },

  setDataMasterParty(state, data) {
    state.dataMasterParty = data;
  },

  setDataCostInvoiceList(state, data) {
    state.dataCostInvoiceList = data;
  },

  setDataMasterShipper(state, data) {
    state.dataMasterShipper = data;
  },

  setDataMasterConsignee(state, data) {
    state.dataMasterConsignee = data;
  },

  setDataMasterStockPoint(state, data) {
    state.dataMasterStockPoint = data;
  },

  // Mutations to set flags indicating data loading status
  setDataMasterDebtorLoaded(state, status) {
    state.isDataMasterDebtorLoaded = status;
  },

  setDataMasterDebtorByCustomerLoaded(state, status) {
    state.isDataMasterDebtorByCustomerLoaded = status;
  },

  setDataMasterVendorLoaded(state, status) {
    state.isDataMasterVendorLoaded = status;
  },

  setDataMasterPartyLoaded(state, status) {
    state.isDataMasterPartyLoaded = status;
  },

  setDataCostInvoiceListLoaded(state, status) {
    state.isDataCostInvoiceListLoaded = status;
  },

  setDataMasterShipperLoaded(state, status) {
    state.isDataMasterShipperLoaded = status;
  },

  setDataMasterConsigneeLoaded(state, status) {
    state.isDataMasterConsigneeLoaded = status;
  },

  setDataMasterStockPointLoaded(state, status) {
    state.isDataMasterStockPointLoaded = status;
  },

  // Additional mutations for managing parameters
  setCustomer(state, customer) {
    state.customer = customer;
  },

  setCostInvoiceParams(state, { status, office_id }) {
    state.status = status;
    state.office_id = office_id;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
