// masterLocation.js
import axios from "axios";

const state = {
  dataMasterVehicle: [],
  dataMasterVehicleTool: [],
  dataMasterEquipmentType: [],
  dataMasterEquipment: [],
  dataMasterVehicleClass: [],

  // Flags to track whether data has been fetched
  isDataMasterVehicleLoaded: false,
  isDataMasterVehicleToolLoaded: false,
  isDataMasterEquipmentTypeLoaded: false,
  isDataMasterEquipmentLoaded: false,
  isDataMasterVehicleClassLoaded: false,
};

const actions = {
  async fetchDataMasterVehicle({ commit, state }) {
    if (!state.isDataMasterVehicleLoaded) {
      try {
        const response = await axios.get("api/webui/master/vehicle", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterVehicle", response.data.data);
        commit("setDataMasterVehicleLoaded", true);
      } catch (error) {
        console.error("Error fetching master vehicle data:", error);
      }
    }
  },

  async fetchDataMasterVehicleTool({ commit, state }) {
    if (!state.isDataMasterVehicleToolLoaded) {
      try {
        const response = await axios.get("api/webui/master/vehicle_tool", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterVehicleTool", response.data.data);
        commit("setDataMasterVehicleToolLoaded", true);
      } catch (error) {
        console.error("Error fetching master vehicle tool data:", error);
      }
    }
  },

  async fetchDataMasterEquipmentType({ commit, state }) {
    if (!state.isDataMasterEquipmentTypeLoaded) {
      try {
        const response = await axios.get("api/webui/master/equipment_type", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterEquipmentType", response.data.data);
        commit("setDataMasterEquipmentTypeLoaded", true);
      } catch (error) {
        console.error("Error fetching master equipment type data:", error);
      }
    }
  },

  async fetchDataMasterEquipment({ commit, state }) {
    if (!state.isDataMasterEquipmentLoaded) {
      try {
        const response = await axios.get("api/webui/master/equipment", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterEquipment", response.data.data);
        commit("setDataMasterEquipmentLoaded", true);
      } catch (error) {
        console.error("Error fetching master equipment data:", error);
      }
    }
  },

  async fetchDataMasterVehicleClass({ commit, state }) {
    if (!state.isDataMasterVehicleClassLoaded) {
      try {
        const response = await axios.get("api/webui/master/vehicle_class", {
          params: {
            is_enable: true,
            is_limit: false,
          },
        });
        commit("setDataMasterVehicleClass", response.data.data);
        commit("setDataMasterVehicleClassLoaded", true);
      } catch (error) {
        console.error("Error fetching master vehicle class data:", error);
      }
    }
  },
};

const mutations = {
  setDataMasterVehicle(state, data) {
    state.dataMasterVehicle = data;
  },
  setDataMasterVehicleTool(state, data) {
    state.dataMasterVehicleTool = data;
  },
  setDataMasterEquipmentType(state, data) {
    state.dataMasterEquipmentType = data;
  },
  setDataMasterEquipment(state, data) {
    state.dataMasterEquipment = data;
  },
  setDataMasterVehicleClass(state, data) {
    state.dataMasterVehicleClass = data;
  },

  // Mutations to set flags indicating data loading status
  setDataMasterVehicleLoaded(state, status) {
    state.isDataMasterVehicleLoaded = status;
  },
  setDataMasterVehicleToolLoaded(state, status) {
    state.isDataMasterVehicleToolLoaded = status;
  },
  setDataMasterEquipmentTypeLoaded(state, status) {
    state.isDataMasterEquipmentTypeLoaded = status;
  },
  setDataMasterEquipmentLoaded(state, status) {
    state.isDataMasterEquipmentLoaded = status;
  },
  setDataMasterVehicleClassLoaded(state, status) {
    state.isDataMasterVehicleClassLoaded = status;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
