const contractChildRoutes = (prefix) => [
  {
    path: "cost_contract",
    name: prefix + "_cost_overview",
    component: () => import("@/views/contract/cost/CostContractOverview.vue"),
  },
  {
    path: "cost_contract/create",
    name: prefix + "_cost_add",
    component: () => import("@/views/contract/cost/CostContractCreate.vue"),
  },
  {
    path: "cost_contract/show/:id",
    name: prefix + "_cost_show",
    component: () => import("@/views/contract/cost/CostContractShow.vue"),
  },
  {
    path: "revenue_contract",
    name: prefix + "_revenue_overview",
    component: () => import("@/views/contract/revenue/RevenueContractOverview.vue"),
  },
  {
    path: "revenue_contract/create",
    name: prefix + "_revenue_add",
    component: () => import("@/views/contract/revenue/RevenueContractCreate.vue"),
  },
  {
    path: "revenue_contract/show/:id",
    name: prefix + "_revenue_show",
    component: () => import("@/views/contract/revenue/RevenueContractShow.vue"),
  },
];

export default contractChildRoutes;
