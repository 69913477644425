// store/auth.js
const state = {
    isAuthenticated: false,
  };
  
  const mutations = {
    SET_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
      // console.log(isAuthenticated)
    },
  };
  
  const actions = {
    setAuthenticated({ commit }, isAuthenticated) {
      commit("SET_AUTHENTICATED", isAuthenticated);
      // console.log(isAuthenticated)
    },
  };
  
  const getters = {
    isAuthenticated: (state) => state.isAuthenticated,
  };
  
  export default {
    state,
    mutations,
    actions,
    getters,
  };
  